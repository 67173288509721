import { useContext } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import AdminPage from './Admin/AdminPage';
import AuthRoute from './Shared/Nav/AuthRoute';
import LinkAccountPage from './Authentication/LinkAccountPage';
import Loading from './Shared/Loading';
import Login from './Authentication/Login';
import Main from './Shared/Nav/Main';
import MemberInvitePage from './ProviderManagement/MemberInvitePage';
import ProviderAdminPage from './Admin/ProviderAdminPage';
import ProviderManagementMain from './ProviderManagement/ProviderManagementMain';
import ProviderManagementPage from './ProviderManagement/ProviderManagementPage';
import ProviderRegistrationPage from './ProviderManagement/ProviderRegistrationPage';
import ProviderRequestDetails from './Admin/Components/ProviderRequestDetails';
import ProviderRequests from './Admin/ProviderRequests';
import RoleDetailsPage from './ProviderManagement/RoleDetailsPage';
import RoleManagementPage from './ProviderManagement/RoleManagementPage';
import TeamMemberRegistrationB2CTriggerPage from './Authentication/TeamMemberRegistrationB2CTriggerPage';
import { ToastContainer } from 'react-toastify';
import UserDetailsPage from './ProviderManagement/UserDetailsPage';
import UserInvitePage from './ProviderManagement/UserInvitePage';
import { UserProfileContext } from './Hooks/useProfile';
import UserProfilePage from './User/UserProfile'
import ViewInvitation from './User/ViewInvitation';
import ViewRegistration from './Authentication/ViewRegistration';
import { useProfile } from './Hooks/useProfile';
import RegistrationComplete from './Authentication/Components/RegistrationComplete';

import { AppConfigContext } from './AppConfig';

let router: any;

const Routes = () => {
	const appConfig = useContext(AppConfigContext);
	const userProfile = useProfile(appConfig);

	if(!(userProfile.IsLoading || router)) {
		router = createBrowserRouter(createRoutesFromElements(
		  <>
			<Route path="/memberregistration" element={<TeamMemberRegistrationB2CTriggerPage />} />
			<Route path="/linkaccount" element={<LinkAccountPage />} />
			{/* <Route path="/feedback" element={<FeedbackPage />} /> */}
			<Route path="/register" element={<ViewRegistration />} />
			<Route path="/login" element={<Login />} />
			<Route path="/" element={<Login />} />
			<Route element={<AuthRoute />}>
			  <Route path="registrationcompleted" element={<RegistrationComplete />} />
			  <Route path="memberinvite" element={<MemberInvitePage />} />
			  <Route path="/main" element={<Main />} >
				<Route path="user" >
				  <Route path="profile" element={<UserProfilePage />} />
				  <Route path="viewinvitation" element={<ViewInvitation />} />
				</Route>
				<Route path="provider/:providerId" element={<ProviderAdminPage />} >
				  <Route path="rolemanagement" >
					<Route path="" element={<RoleManagementPage />} />
					<Route path="create" element={<RoleDetailsPage />} />
					<Route path="details/:id" element={<RoleDetailsPage />} />
				  </Route>
				  <Route path="user/:userId" element={<UserDetailsPage />} />
				  <Route path="userinvite" element={<UserInvitePage />}/>
				  <Route path="usersearch" element={<ProviderManagementPage />}/>
				  <Route path="settings" element={<ProviderManagementMain />} />
				</Route>
				<Route path="admin" element={<AdminPage />} >
				  <Route path="providerrequests" element={<ProviderRequests />} />
				  <Route path="providerrequest/:id" element={<ProviderRequestDetails />} />
				  <Route path="providerrequests/registration" element={<ProviderRegistrationPage />} />
				</Route>
			  </Route>
			</Route>
		  </>
		), { basename: appConfig.PUBLIC_URL });
	}

	return (
		<UserProfileContext.Provider value={userProfile}>
		  {
			userProfile.IsLoading 
			  ? <Loading />
			  : 
				<>
				  <RouterProvider router={router}></RouterProvider>
				  <ToastContainer /> 
				</>
		  }
		</UserProfileContext.Provider>
	);
};

export default Routes;