import { Configuration, LogLevel, EndSessionRequest } from "@azure/msal-browser";
import { LoginRequest } from "./Models/LoginRequest";
import { ApiConfig } from "./Models/ApiConfig";
import { B2cPolicies } from "./Models/B2cPolicies";
import { AppConfig } from '../AppConfig';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export class B2cConfiguration {
    constructor(appConfig: AppConfig) {
        /**
         * Enter here the user flows and custom policies for your B2C application
         * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
         * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
         */
        const b2cPolicies = this.b2cPolicies = {
            authorities: {
                signUpSignIn: {
                    authority: appConfig.B2C_SIGN_IN_AUTHORITY || 'https://ClientExchangeDev.b2clogin.com/ClientExchangeDev.onmicrosoft.com/B2C_1_signin1'
                },
                signUp: {
                    authority: appConfig.B2C_REGISTRATION_AUTHORITY ||'https://ClientExchangeDev.b2clogin.com/ClientExchangeDev.onmicrosoft.com/B2C_1_ProviderRegistration'
                }
            },
            authorityDomain: appConfig.B2C_AUTHORITY || 'ClientExchangeDev.b2clogin.com'
        };
        // Config object to be passed to Msal on creation
        this.msalConfig = {
            auth: {
                clientId: appConfig.B2C_CLIENTID || '',
                authority: b2cPolicies.authorities.signUpSignIn.authority,
                knownAuthorities: [b2cPolicies.authorityDomain || ''],
                postLogoutRedirectUri: "/"
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: isIE || isEdge || isFirefox
            },
            system: {
                allowNativeBroker: false, // Disables WAM Broker
                loggerOptions: {
                    loggerCallback: (level: any, message: any, containsPii: any) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                return;
                        }
                    }
                }
            }
        };
        const scopes = this.scopes = [
            appConfig.B2C_ENTITY_READ_SCOPE || 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Entity.Read',
            appConfig.B2C_ENTITY_WRITE_SCOPE || 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Entity.Write',
            appConfig.B2C_CHAT_READ_SCOPE || 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Chat.Read',
            appConfig.B2C_CHAT_WRITE_SCOPE || 'https://ClientExchangeDev.onmicrosoft.com/ce-api/Chat.Write',
            appConfig.B2C_USER_PROFILE_SCOPE || 'https://ClientExchangeDev.onmicrosoft.com/ce-api/userprofile',
        ];
        // Scopes you add here will be prompted for consent during login
        this.loginRequest = {
            scopes: ["openid", 
                ...scopes
            ],
            redirectUri: ("object" === typeof window && window.location?.origin) || appConfig.DOMAIN || 'https://clientexchange-dev.eccovia.com'
        };
        this.logoutRequest = {
            postLogoutRedirectUri: '/',
            authority: b2cPolicies.authorities.signUpSignIn.authority
        };
        this.apiConfig = {
            scopes: scopes,
            uri: appConfig.B2C_URI || 'https://ClientExchangeDev.onmicrosoft.com/ce-api'
        };
    }
    apiConfig : ApiConfig;
    b2cPolicies : B2cPolicies;
    loginRequest : LoginRequest;
    logoutRequest : EndSessionRequest;
    msalConfig : Configuration;
    scopes : string[];
}