import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './bootstrap.css'

import Routes from './Routes';
import Loading from './Shared/Loading';

import { AppConfigContext, useAppConfig } from './AppConfig';
import { datadogRum } from '@datadog/browser-rum';

let initialized = false;

function App() {
  const appConfig = useAppConfig();

  if(appConfig.DATA_DOG_APP_ID && !initialized) {
    datadogRum.init({
      applicationId: appConfig.DATA_DOG_APP_ID,
      clientToken: appConfig.DATA_DOG_CLIENT_TOKEN,
      site: 'us3.datadoghq.com',
      service: appConfig.DATA_DOG_CLIENT_TOKEN,
      env: appConfig.ENV,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: true,
    });

    initialized = true;
  }
  
  return (
    <AppConfigContext.Provider value={appConfig}>
      {
        appConfig.IsLoading
          ? <Loading />
          : <Routes />
      }
    </AppConfigContext.Provider>
  );
}

export default App;
