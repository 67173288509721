import { AccountInfo } from "@azure/msal-browser";

export interface UserProfile {
    AppUser: ApplicationUser | undefined,
    IsLoggedIn: boolean,
    ActiveProvider: ProviderOption,
    SetActiveProvider: Function,
    ProviderOptions: ProviderOption[],
    NewUser: boolean,
    B2CUser: AccountInfo | undefined,
    PlatformAdmin: boolean,
    ProviderAdmin: boolean,
    CanAccessApp: boolean,
    IsLoading: boolean,
    ForceRefresh: Function
}

export interface IDictionary<T> {
    [key: string]: T;
}

export interface AssociatedProvider {
    id: string,
    status: number
}

export interface ProviderOption {
    ProviderId: string,
    ProviderName: string,
    Identifiers: Identifier[]
}

export interface ProviderRegistration {
    address: any,
    completedDate: Date,
    emailIdentifier: any,
    id: string,
    identity: any,
    completionStatus: RegistrationCompletionStatus,
    providerName: string,
    registeredByName: string,
    npi: string,
    taxId: string,
    phone: string,
    reviewedBy: string,
    requestedDate: Date,
    updatedDate: Date
}

export interface ProviderRegistrationSearchRequest {
    id: string | undefined | null,
    completionStatus: RegistrationCompletionStatus | null,
    name: string | null
}

export enum RegistrationCompletionStatus {
    pending,
    completed,
    declined,
    failed
}

export interface ProviderRegistrationUpdateRequest {
    id: string,
    completionStatus: RegistrationCompletionStatus
}

export enum SecurableType {
    Unknown,
    Entity,
    Role,
    Group
}

export enum FeedbackRequestType {
    Issue = 108,
    Suggestion = 116,
    Question = 117,
    UATFeedback = 118,
    CustomerEnhancement = 119
}

export interface ApplicationUser {
    accesses: Access[],
    entity: Entity,
    providerRegistration: ProviderRegistration
}

export interface Access {
    id: string,
    scope: Scope[],
    name: string,
    parentId: string | null,
    type: SecurableType
}

export interface Scope {
    deny: boolean,
    by: string,
    lvl: number,
    permission: number,
    scope: string
}

export interface EntityRoles {
    entity: Entity,
    roles: Access[]
}

export interface Entity {
    id: string,
    identities: [],
    updatedBy: string,
    updatedDate: Date,
    identifiers: Identifier[],
    name: string,
    type: EntityType,
    currentProvider: string,
    associatedProviders: AssociatedProvider[]
}

export interface EntityType {
    app: boolean,
    client: boolean,
    provider: boolean,
    user: boolean
}

export interface Identifier {
    type?: IdentifierType | undefined,
    value: string,
	verified: boolean | undefined
}

export enum IdentifierType 
{
	Unknown = 0,
	Email = 1,
	Phone = 2,
	Url = 3,
}

export interface ScopeRequest {
    scope: string,
    permission: Permission,
    deny: Permission
}

export enum Permission {
    None = 0,
    Read, 
    Write,
    ReadWrite = Read | Write,
    Delete,
    ControlAccess = 8,
    Full = ReadWrite | Delete | ControlAccess
}

export interface ScopeView {
    id: string,
    name: string,
    description: string
}