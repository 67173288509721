import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ProviderRegistration, ProviderRegistrationSearchRequest, ProviderRegistrationUpdateRequest, RegistrationCompletionStatus } from "../../Shared/Models";
import { Slide, toast } from "react-toastify";

import ApiHelper from '../../Shared/ApiHelper';
import { ToastConfig } from '../../Shared/ToastConfig';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { UserProfileContext } from '../../Hooks/useProfile';
import { AppConfigContext } from '../../AppConfig';

const ProviderRequestDetails: FunctionComponent = () => {
    const { id } = useParams();
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const [registration, setRegistration] = useState<ProviderRegistration | undefined>();
    const [badgeClass, setBadgeClass] = useState<string>('');
    const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
    const userProfile = useContext(UserProfileContext);
    const appConfig = useContext(AppConfigContext);
    
    useEffect(() => {
        if(!appConfig.CLIENTEX_APIBASE)
            return;

        const searchRequest: ProviderRegistrationSearchRequest = {
            id,
            name: null,
            completionStatus: null
        };

        api.callApi(
            instance,
            [appConfig.B2C_SCOPE],
            `${appConfig.CLIENTEX_APIBASE}/admin/provider/registrations/search`,
            "POST",
            JSON.stringify(searchRequest),
            undefined
        ).then(async (result: Response) => {
            if (result.ok && result.status != 204) {
                var data = await result.json();
                setRegistration(data[0]);
            }
        });
    }, [api, instance, id, appConfig])

    useEffect(() => {
        switch(registration?.completionStatus) {
            case RegistrationCompletionStatus.pending:
                setBadgeClass('badge-warning');
                break;
            case RegistrationCompletionStatus.completed:
                setBadgeClass('badge-success');
                break;
            case RegistrationCompletionStatus.declined:
                setBadgeClass('badge-dark');
                break;
            case RegistrationCompletionStatus.failed:
                setBadgeClass('badge-danger');
                break;
        }
    }, [registration])

    const onClickHandler = (status: RegistrationCompletionStatus) => {
        setFormSubmitting(true);
        var updateRequest: ProviderRegistrationUpdateRequest = {
            id: registration?.id || '',
            completionStatus: status
        };

        if(appConfig.CLIENTEX_APIBASE) {
            api.callApi(
                instance,
                [appConfig.B2C_SCOPE],
                `${appConfig.CLIENTEX_APIBASE}/admin/provider/registration`,
                "PATCH",
                JSON.stringify(updateRequest),
                undefined
            ).then(async (result: Response) => {
                if (result.ok) {
                    var data = await result.json();
                    setRegistration(data);
                    toast.success("Successfully updated the registration request", ToastConfig);
                    const needsRefresh = userProfile?.AppUser?.entity?.identifiers.find(x => x.value === registration?.emailIdentifier.value);
                    if (needsRefresh) {
                        userProfile?.ForceRefresh();
                    }
                }
            }).catch(err => {
                toast.warning("Failed to update the registration request. We are investigating.", ToastConfig)
            })
            .finally(() => setFormSubmitting(false));
        }
    }

    return (
        <>
        <h4>Provider Request Details</h4>
        <div>
            <span className={`badge badge-pill ${badgeClass}`}>{RegistrationCompletionStatus[registration?.completionStatus || 0]}</span>
        </div>
        <hr />
        <div className="justify-content-around align-items-center mt-3 w-100">
            
            <div>
                <div className="card">
                    <div className="card-header">
                        <h6>Basic Information</h6>
                    </div>
                    <div className="card-body">
                        <div className="form-row mb-2">
                            <div className="col">
                                <label>Provider Name</label>
                                <input type="text" className="form-control" id="providerNameInput" name="providerName" disabled={true} defaultValue={registration?.providerName}/>
                            </div>
                        </div>
                        <div className="form-row mb-2">
                            <div className="col">
                                <label>Email</label>
                                <input type="text" className="form-control" id="emailInput" name="email" disabled={true} defaultValue={registration?.emailIdentifier?.value}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h6>Contact Information</h6>
                    </div>
                    <div className="card-body">
                        <div className="form-row mb-2">
                            <div className="col">
                                <label>Phone</label>
                                <input type="text" className="form-control" id="phoneInput" name="phone" disabled={true} defaultValue={registration?.phone}/>
                            </div>
                        </div>
                        <div className="form-row mb-2">
                            <div className="col">
                                <label>Address</label>
                                <input type="text" className="form-control" id="street1Input" name="street1" disabled={true} defaultValue={registration?.address?.street1}/>
                                <input type="text" className="form-control" id="street2Input" name="street2" disabled={true} defaultValue={registration?.address?.street2}/>
                                <input type="text" className="form-control" id="cityInput" name="city" disabled={true} defaultValue={registration?.address?.city }/>
                                <input type="text" className="form-control" id="stateInput" name="state" disabled={true} defaultValue={registration?.address?.state}/>
                                <input type="text" className="form-control" id="postalInput" name="postalCode" disabled={true} defaultValue={registration?.address?.postalCode}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h6>Tax and Other Information</h6>
                    </div>
                    <div className="card-body">
                        <div className="form-row mb-2">
                            <div className="col">
                                <label>Tax ID (EIN/SSN)</label>
                                <input type="text" className="form-control" id="taxIdInput" name="taxid" disabled={true} defaultValue={registration?.taxId}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <label>NPI</label>
                                <input type="text" className="form-control" id="npiInput" name="npi" disabled={true} defaultValue={registration?.npi}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="justify-content-center">
                    <div className="mt-2 mb-4 w-100">

                        <button className="btn btn-primary w-100" disabled={formSubmitting || registration?.completionStatus === RegistrationCompletionStatus.completed} onClick={e => onClickHandler(RegistrationCompletionStatus.completed)}>Approve</button>
                        <button className="btn btn-outline-primary mt-1 w-100" disabled={formSubmitting || registration?.completionStatus === RegistrationCompletionStatus.completed} onClick={e => onClickHandler(RegistrationCompletionStatus.declined)}>Reject</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default ProviderRequestDetails;