import { FunctionComponent, useContext, useEffect, useState } from "react";

import ApiHelper from "../Shared/ApiHelper";
import { AppConfigContext } from "../AppConfig";
import { B2cConfiguration } from "./AuthConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../Hooks/useProfile";

const LinkAccountPage: FunctionComponent = () => {
    const { instance, accounts } = useMsal();
    const appConfig = useContext(AppConfigContext);
    const [api] = useState(new ApiHelper());
    const userProfile = useContext(UserProfileContext);
    const [isLinked, setIsLinked] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(!appConfig.CLIENTEX_APIBASE || userProfile.IsLoading || accounts.length === 0)
            return;
        
        const { loginRequest } = new B2cConfiguration(appConfig);
        api.callApi(
            instance,
            loginRequest.scopes,
            `${appConfig.CLIENTEX_APIBASE}/entity/linkaccount`,
            "POST",
            JSON.stringify({})
        ).then(res => {
            userProfile.ForceRefresh()
            setIsLinked(true);
        }).catch(err => console.error(err));
    }, [api, instance, navigate, accounts, appConfig, userProfile, userProfile.IsLoading]);

    useEffect(()  => {
        if(!userProfile.IsLoading && isLinked) {
            navigate("/registrationcompleted");
        }
    }, [userProfile.IsLoading, isLinked, navigate]);

    return (
        <>
            Linking accounts please wait... 
        </>
    )
}

export default LinkAccountPage;