import { FunctionComponent, useContext, useEffect } from "react";
import { B2cConfiguration } from "./AuthConfig";
import { AppConfigContext } from "../AppConfig";

import { useMsal } from "@azure/msal-react";
import { RedirectRequest } from "@azure/msal-browser";

const TeamMemberRegistrationB2CTriggerPage: FunctionComponent = () => {
    const { instance } = useMsal();
    const appConfig = useContext(AppConfigContext);
    
    useEffect(() => {
        if(appConfig.IsLoading)
            return;
        
        const b2cConfig = new B2cConfiguration(appConfig);
        const {b2cPolicies, loginRequest} = b2cConfig;
        
        const domain = appConfig.DOMAIN;
        const startPageRoute = appConfig.TEAMMEMBER_REGISTRATION_STARTPAGE;

        const registrationRequest: RedirectRequest = {
            redirectStartPage: domain + startPageRoute,
            redirectUri: domain,
            authority: b2cPolicies.authorities.signUp.authority,
            scopes: loginRequest.scopes
        };
        
        instance.loginRedirect(registrationRequest)
        .catch(err => console.error("Error redirecting to login", err));
    });

    return (
        <>
            Please register
        </>
    )
}

export default TeamMemberRegistrationB2CTriggerPage;