import { EntityRoles, IdentifierType } from '../Shared/Models';
import { useContext, useEffect, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import { Link } from 'react-router-dom';
import { ToastConfig } from '../Shared/ToastConfig';
import { UserProfileContext } from '../Hooks/useProfile';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import useTooltip from '../Hooks/useTooltip';
import { AppConfigContext } from '../AppConfig';

const ProviderManagementPage = () => {

   const user = useContext(UserProfileContext);
   const appConfig = useContext(AppConfigContext);
   const [ emailInput, setEmailInput ] = useState<string>('');
   const [ teamMembers, setTeamMembers ] = useState<EntityRoles[]>([]);
   const [api] = useState(new ApiHelper());
   const { instance } = useMsal();

   useTooltip([teamMembers]);

   useEffect(() => {
      if(!user || appConfig.IsLoading || !appConfig.CLIENTEX_APIBASE)
         return;
      api.callApi(
         instance, 
         [appConfig.B2C_SCOPE],
         `${appConfig.CLIENTEX_APIBASE}/provider/${user.ActiveProvider.ProviderId}/users`,
         "GET")
      .then(apiCallbackHandler);
   }, [user, appConfig, api, instance])

   const inviteTeamMemberClickHandler = () => {
      api.callApi(
         instance, 
         [appConfig.B2C_SCOPE],
         `${appConfig.CLIENTEX_APIBASE}/provider/${user.ActiveProvider.ProviderId}/inviteteammember`,
         "POST",
         JSON.stringify({ email: emailInput }))
      .then(apiCallbackHandler)
      .then(() => toast.success("User has been invited and/or permissions added", ToastConfig))
      .catch((err) => toast.error(err, ToastConfig));
   }

   const deleteClick = (securableId: string) => {
      api.callApi(
         instance, 
         [appConfig.B2C_SCOPE],
         `${appConfig.CLIENTEX_APIBASE}/provider/${user.ActiveProvider.ProviderId}/users/${securableId}`,
         "DELETE")
      .then(() => {
         toast.success("User has been successfully removed", ToastConfig)
         const updatedTeamMembers = teamMembers.filter(x => x.entity.id !== securableId);
         setTeamMembers(updatedTeamMembers);
      });
   }

   const apiCallbackHandler = async (res: Response) => {
      var data: EntityRoles[] = await res.json();
      setTeamMembers(data);
   }

   const GetActiveStatus = (entityRole: EntityRoles): boolean => {
      return entityRole.entity.identifiers.find(x => x.type === IdentifierType.Email)?.verified || false;
   }

    return (
         <>
            <div className="row">
               <div className="col-sm-12">
                  <div className="card">
                     <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Provider User Management</h4>
                        </div>
                     </div>
                     <div className="card-body">
                        <div className="row justify-content-between">
                           <div className="col-sm-6 col-md-6">
                              <div id="user_list_datatable_info" className="dataTables_filter">
                                 <form className="mr-0 mr-md-3 position-relative" onSubmit={e => e.preventDefault()}>
                                    <div className="form-group mb-0 flex-column">
                                    <div className="">
                                       <input type="text" className="form-control" onKeyDown={e => e.key === 'Enter' && inviteTeamMemberClickHandler()} onChange={(e) => setEmailInput(e.target.value) } placeholder="Enter New Teammate's Email" aria-controls="user-list-table" />
                                    </div>
                                    <div className="">
                                       <button type="button" className="btn btn-primary w-100" onClick={inviteTeamMemberClickHandler}>Add Teammate</button>
                                    </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                           <div className="col-sm-6 col-md-6">

                           </div>
                        </div>
                        <div className="table-responsive">
                           <table id="user-list-table" className="table table-striped dataTable mt-4" role="grid" aria-describedby="user-list-page-info">
                              <thead>
                                 <tr className="ligth">
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th style={{ minWidth: '100px' }}>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {teamMembers?.map((member: EntityRoles, index: number) => (
                                    <tr key={index}>
                                       <td>{member.entity.name}</td>
                                       <td>{member.entity.identifiers.find((x: any) => x.type === 1)?.value}</td>
                                       <td><span className={`${GetActiveStatus(member) ? 'badge bg-primary-light' : 'badge badge-pill badge-warning' }`}>{GetActiveStatus(member) ? "Active" : "Pending" }</span></td>
                                       <td>
                                          <div className="d-flex align-items-center list-user-action">
                                             <Link className="btn btn-sm bg-primary mr-2" data-toggle="tooltip" data-placement="top" title="Edit User" data-original-title="Edit" to={`../user/${member.entity.id}`} state={{ EntityRole: member }}><i className="ri-pencil-line mr-0"></i></Link>
                                             <button disabled={member.entity.id === user.AppUser?.entity.id} className="btn btn-sm bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" onClick={e => deleteClick(member.entity.id)}><i className="ri-delete-bin-line mr-0"></i></button>
                                          </div>
                                       </td>
                                    </tr>
                                 ))}
                              </tbody>
                           </table>
                        </div>
                        <div className="row justify-content-between mt-3">
                           <div id="user-list-page-info" className="col-md-6">
                              <span>Showing 1 to {teamMembers?.length} of {teamMembers?.length} entries</span>
                           </div>
                           {
                           teamMembers !== undefined && teamMembers?.length > 10 &&  
                              (
                                 <div className="col-md-6">
                                 <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-end mb-0">
                                       <li className="page-item disabled">
                                          <a className="page-link" href="#" tabIndex={-1} aria-disabled="true">Previous</a>
                                       </li>
                                       <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                       <li className="page-item"><a className="page-link" href="#">2</a></li>
                                       <li className="page-item"><a className="page-link" href="#">3</a></li>
                                       <li className="page-item">
                                          <a className="page-link" href="#">Next</a>
                                       </li>
                                    </ul>
                                 </nav>
                              </div>
                              )
                           }
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
    )
}

export default ProviderManagementPage;