import '../backend.css';

import { ProviderRegistration, ProviderRegistrationSearchRequest } from '../Shared/Models';
import { useContext, useEffect, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import ProviderRequestListItem from './Components/ProviderRequestListItem'
import { useMsal } from '@azure/msal-react';
import { RegistrationButton } from '../Authentication/Components/RegistrationButton';
import { AppConfigContext } from '../AppConfig';
import Loading from '../Shared/Loading';

const ProviderRequests = () => {
    const [api] = useState(new ApiHelper());
    const { instance } = useMsal();
    const [registrationList, setRegistrationList] = useState<ProviderRegistration[] | undefined>();
    const appConfig = useContext(AppConfigContext);

    useEffect(() => {
        const searchRequest: ProviderRegistrationSearchRequest = {} as ProviderRegistrationSearchRequest;

        if(!appConfig.CLIENTEX_APIBASE)
            return;

        api.callApi(
            instance,
            [appConfig.B2C_SCOPE],
            `${appConfig.CLIENTEX_APIBASE}/admin/provider/registrations/search`,
            "POST",
            JSON.stringify(searchRequest),
            undefined
        ).then(async (result: Response) => {
            var data = await result.json();
            if (data.length > 0) {
                setRegistrationList(data);
            }
        });
    }, [api, instance, appConfig]);

    return (
        <>
            <div className="d-flex align-content-center">
                <h4>Provider Requests</h4>
                <span className="ml-2">
                    <RegistrationButton />
                </span>
            </div>
            <hr />
            <div className="row mt-3">
                <div className="col-sm-12 col-lg-12">
                    <div className="row mb-2">
                        <div className="col-12">
                            <button className=" w-100 btn btn-primary">Filter</button>
                        </div>
                    </div>
                    { !registrationList
                    ? <Loading />
                    : registrationList.length === 0 
                        ? <p>There are no provider registrations</p>
                        : registrationList?.map(listItem => <ProviderRequestListItem Request={listItem} key={listItem.id}/>)
                    }
                </div>
            </div>
        </>

    );
}

export default ProviderRequests;