import { useContext/*, useState*/ } from 'react';

import EccoviaLogo from '../Shared/images/eccovia-logo-transparent.png';
import { UserProfileContext } from '../Hooks/useProfile';
import { IdentifierType } from '../Shared/Models';
//import { AppConfigContext } from '../AppConfig';

// interface SMSConfig {
//     providerName: string,
//     accountSid: string,
//     phone: string,
//     authToken: string
// }


const ProviderManagementMain = () => {
    // const [smsFormDirty, setSmsFormDirty] = useState<boolean>(false);
    // const { instance } = useMsal();
    // const [api] = useState(new ApiHelper());
    // const [smsConfig, setSmsConfig] = useState<SMSConfig>();
    const userProfile = useContext(UserProfileContext);
   // const appConfig = useContext(AppConfigContext);
    
    // const initializeSmsConfig = useCallback(() => {
    //     api.callApi(
    //         instance, 
    //         [appConfig.B2C_SCOPE],
    //         `${appConfig.CLIENTEX_APIBASE}/provider/${userProfile.ActiveProvider.ProviderId}/smsconfig`,
    //         "GET")
    //      .then(async results => {
    //         if (results.status === 204) {
    //             return;
    //         }
    //         var data = await results.json();
    //         setSmsConfig(data);
    //      });
    // }, [instance, api] );

    // useEffect(() => {
    //     initializeSmsConfig();
    // }, [instance, api, initializeSmsConfig]);

    // const saveSmsConfig = (e: any) => {
    //     e.preventDefault();
    //     const target = e.target;
    //     const formData = new FormData(target);
    //     const formJson = Object.fromEntries(formData.entries());
        

    //     api.callApi(
    //         instance,
    //         [appConfig.B2C_SCOPE],
    //         `${appConfig.CLIENTEX_APIBASE}/provider/${userProfile.ActiveProvider.ProviderId}/smsconfig`,
    //         "POST",
    //         JSON.stringify(formJson)
    //     ).then(async result => {
    //         await result.json();
    //         if (result.ok) {
    //             toast.success("SMS Config Saved", ToastConfig);
    //             setSmsFormDirty(false);
    //         }
    //     })
    // }

    return (
        <>
            <div className="row">
                <div className="col-md-6 col-lg-4">
                    <div className="card car-transparent" style={{ background: "#fcfcfc"}}>
                        <div className="card-body p-0" >
                            <div className="profile-image position-relative d-flex justify-content-center">
                                <img src={EccoviaLogo} className="img-fluid rounded" alt="profile" />
                            </div>
                            <div className="profile-overly">
                                <h3>{userProfile?.ActiveProvider.ProviderName}</h3>
                                <span>Provider</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-6">
                    <div className="card card-block card-stretch card-height">
                        <div className="card-header">
                            <div className="header-title">
                                <h4 className="card-title">Contact Information</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <ul className="list-inline p-0 m-0 iq-contact-rest">
                                <li className="mb-3 d-flex align-items-center">
                                <span><i className="mr-3">
                                    <svg width="20" className="svg-icon" id="up-02" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                                    </svg>
                                </i></span>
                                <p className="mb-0 font-size-16 line-height">{userProfile.ActiveProvider?.Identifiers?.find(x => x.type === IdentifierType.Phone)?.value ?? "No Phone Set"}</p>
                                </li>
                                <li className="mb-3 d-flex align-items-center">
                                <span><i className="mr-3">
                                    <svg width="20" className="svg-icon" id="up-03" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"></path>
                                    </svg>
                                </i></span>
                                <p className="mb-0 font-size-16 line-height">{userProfile.ActiveProvider?.Identifiers?.find(x => x.type === IdentifierType.Email)?.value ?? "No Email Set"}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-4 col-lg-4"></div>     
                <div className="col-md-4 col-lg-6">
                    <div className="card card-block card-stretch card-height">
                        <div className="card-header">
                            <div className="header-title">
                                <h4 className="card-title">Settings</h4>
                            </div>
                        </div>
                        <div className="card-body d-flex flex-wrap flex-column">
                            <div className="col justify-content-center d-flex flex-column">
                                <h5 className="mb-1 text-center">SMS Configuration</h5>
                                <form name="smsConfigForm" method="post" onSubmit={saveSmsConfig}>
                                    <div className="mt-1 d-flex form-group justify-content-center align-items-center row">
                                        <label className="col-4">Service</label>
                                        <select className="col-8 form-control" name="providerName">
                                            <option value="Twilio">Twilio</option>
                                        </select>
                                    </div>
                                    <div className="mt-1 d-flex form-group justify-content-center align-items-center row">
                                        <label className="col-4">Phone Number</label>
                                        <input className="col-8 form-control" defaultValue={smsConfig?.phone} name="phone" onChange={e => setSmsFormDirty(true) } required/>
                                    </div>
                                    <div className="mt-1 d-flex form-group justify-content-center align-items-center row">
                                        <label className="col-4">Account SID</label>
                                        <input className="col-8 form-control" defaultValue={smsConfig?.accountSid} name="accountSid" onChange={e => setSmsFormDirty(true) } required />
                                    </div>
                                    <div className="mt-1 d-flex form-group justify-content-center align-items-center row">
                                        <label className="col-4">Auth Token</label>
                                        <input className="col-8 form-control" defaultValue={"*********"} name="authToken" onChange={e => setSmsFormDirty(true) } required />
                                    </div>
                                    <div className="row">
                                        <button type="submit" className="btn btn-primary w-100" disabled={!smsFormDirty}>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-2"></div>     

            </div> */}
        </>
    )
};


export default ProviderManagementMain;
