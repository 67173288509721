import './index.css';

import { EventType, PublicClientApplication } from "@azure/msal-browser";

import App from './App';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { loadConfig } from './AppConfig';
import { B2cConfiguration } from "./Authentication/AuthConfig";
import reportWebVitals from './reportWebVitals';

loadConfig().then((appConfig) => {
  const { msalConfig } = new B2cConfiguration(appConfig);
  const msalInstance = new PublicClientApplication(msalConfig);
  
  msalInstance.initialize().then(() => {
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  
    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();
  
    msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS
        ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = event?.payload?.account;
        msalInstance.setActiveAccount(account);
      }
    });
  
    const container = document.getElementById("root");
    const root = ReactDOM.createRoot(container as Element);
  
    root.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </React.StrictMode>
    );
  });
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
