/* eslint-disable @typescript-eslint/no-empty-function */

import { Access, ApplicationUser, Entity, Permission, ProviderOption, Scope, UserProfile } from '../Shared/Models';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import { useMsal } from '@azure/msal-react';
import { AppConfig, AppConfigContext } from '../AppConfig';

export const UserProfileContext = createContext<UserProfile>({} as UserProfile);

export function useProfile(appConfig: AppConfig): UserProfile {

    const [api] = useState(new ApiHelper());
    const { instance, accounts } = useMsal();
    const [ user, setUser] = useState<ApplicationUser | any>();
    const [ isPlatformAdmin, setIsPlatformAdmin] = useState<boolean>(false);
    const [isProvidermAdmin, setIsProviderAdmin] = useState(false);
    const isLoggedIn = accounts.length > 0 && !!user;
    const [ activeProvider, setActiveProvider ] = useState<ProviderOption>({} as ProviderOption);
    const [ providerOptions, setProviderOptions] = useState<ProviderOption[]>([]);
    const [ isNewUser, setIsNewUser] = useState<boolean>(false);
    const [ isLoading, setIsLoading] = useState<boolean>(false);
    const [ forceRefresh, setForceRefresh] = useState<boolean>(false);
    const adminUserScope = "admin.user";

    const hasAdminReadPermission = useCallback((scope: Scope): boolean => {
        return hasPermission(scope, adminUserScope, Permission.Read);
    }, []);

    const userHasAdminReadPermission = useCallback((): boolean => {
        return user?.accesses?.find((access: Access) => access.id === activeProvider.ProviderId)?.scope?.find((scope: Scope) => hasAdminReadPermission(scope)) !== undefined;
    }, [activeProvider.ProviderId, hasAdminReadPermission, user?.accesses]);

    const hasPermission = (scope: Scope, name: string, permission: Permission ): boolean => {
        return name.startsWith(scope.scope) && (scope.permission & permission) === permission && !scope.deny;
    }

    const GetMe = useCallback(async () => {
        let appUser: ApplicationUser | undefined;
        if(!appConfig.CLIENTEX_APIBASE)
            return;

        return await api.callApi(
            instance,
            [appConfig.B2C_SCOPE],
            `${appConfig.CLIENTEX_APIBASE}/user/me`,
            "GET",
            undefined
        ).then(async (result: Response) => {
            appUser = await result.json();
            const platformAdmin = appUser?.accesses?.find((access: Access) => access.id === appConfig.CLIENTEXCHANGE_ENTITYID) !== undefined;
            setIsPlatformAdmin(platformAdmin);
            const providerAdmin = userHasAdminReadPermission();
            setIsProviderAdmin(providerAdmin);
            if(!user || (appUser?.entity && appUser?.providerRegistration)){
                setUser({ ...user, ...appUser });
            }
            if(appUser?.entity)
                return Promise.all(
                    [...(appUser?.entity?.associatedProviders.map(x => x.id) ?? []), appUser?.entity?.currentProvider ]
                        .filter((value, index, array) => array.indexOf(value) === index) // get unique values
                        .filter((id) => providerOptions.find((o) => o.ProviderId === id) === undefined) // remove values we already have
                        .map(id => api.callApi(
                            instance,
                            [appConfig.B2C_SCOPE],
                            `${appConfig.CLIENTEX_APIBASE}/entity/${id}`,
                            "GET",
                            undefined
                        ))
                );
            else
                return Promise.reject("Null Entity");
        })
        .then(responses => Promise.all(responses.map(async response => await response.json() as Entity)))
        .then(data =>[
            ...providerOptions,
            ...data.map(d => ({
                ProviderId: d.id,
                ProviderName: d.name,
                Identifiers: d.identifiers
            }))
        ])
        .then(providers => {
            setProviderOptions(providers);
            var currentProvider = providers.find(p => p.ProviderId === appUser?.entity?.currentProvider) ?? (providers.length ? providers[0] : undefined);
            if(currentProvider)
                setActiveProvider(currentProvider);
        })
        .catch(err => console.log(err))
        .finally(() =>  {
            setIsLoading(false);
            setForceRefresh(false);
        });
    }, [api, appConfig, instance, userHasAdminReadPermission, user, providerOptions])

    useEffect(() => {
        var providerAdmin = userHasAdminReadPermission();
        setIsProviderAdmin(providerAdmin);
    }, [activeProvider, userHasAdminReadPermission])

    const initializeUserProfile = useCallback(() => {
        if ((accounts.length > 0 && !user?.entity && !user?.providerRegistration && !isNewUser) || forceRefresh) {
            setIsLoading(true);
            GetMe();
        }

        if (accounts.length > 0 && !user && accounts.at(0)?.idTokenClaims?.newUser) {
            setIsNewUser(true);
        }

    }, [accounts, isNewUser, user, forceRefresh, GetMe]);

    useEffect(() => {
        initializeUserProfile();
    }, [accounts, api, initializeUserProfile, instance, forceRefresh]);

    return {
        AppUser: user,
        B2CUser: accounts?.at(0),
        IsLoggedIn: isLoggedIn,
        SetActiveProvider: setActiveProvider,
        ActiveProvider: activeProvider,
        ProviderOptions: providerOptions,
        NewUser: isNewUser,
        PlatformAdmin: isPlatformAdmin,
        ProviderAdmin: isProvidermAdmin,
        CanAccessApp: user === undefined || user.entity?.registeredApps?.find((appId: string) => appId === appConfig.B2C_CLIENTID) !== undefined,
        IsLoading: isLoading,
        ForceRefresh: () => setForceRefresh(true)
    };
}