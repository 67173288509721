import { useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { AppConfigContext } from "../../AppConfig";
import { B2cConfiguration } from "../AuthConfig";

export const SignOutButton = () => {
    const { instance } = useMsal();
    const appConfig = useContext(AppConfigContext);

    const handleLogout = () => {
        if(appConfig.IsLoading ) return;
        const { logoutRequest } = new B2cConfiguration(appConfig);
        instance.logout(logoutRequest);
    }

    return (
        <button className="btn border" onClick={handleLogout} disabled={appConfig.IsLoading}>Sign Out</button>
    )
};