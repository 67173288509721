import { AppConfigContext } from "../../AppConfig";
import { B2cConfiguration, } from "../AuthConfig";
import { useMsal } from "@azure/msal-react";
import { useContext, useState } from "react";

export const SignInButton = () => {
    const { instance } = useMsal();

    const appConfig = useContext(AppConfigContext);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleLogin = async () => {
        if(appConfig.IsLoading) return;
        setAnchorEl(null);

        const { b2cPolicies, loginRequest } = new B2cConfiguration(appConfig);
        const signInRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: loginRequest.scopes,
            redirectUri: loginRequest.redirectUri
        }
        instance.loginRedirect(signInRequest)
            .then(data =>{
                console.log( data )
            } )
            .catch(err => console.log( err ));
    }

    return (
        <button type="button" className="btn btn-primary w-100" onClick={handleLogin} disabled={appConfig.IsLoading}>Sign In</button>
    )
};

export default SignInButton;