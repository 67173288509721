import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppConfigContext } from "../AppConfig";

import EccoviaLogo from '../Shared/images/eccovia-logo-transparent.png';

interface ViewRegistrationParams {
    id: string
}

const ViewRegistration = () => {
    const appConfig = useContext(AppConfigContext);
    const [params, setParams] = useSearchParams();
    const [regId, setRegId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const paramId = params?.get("id") || '';
        setRegId(paramId);
    }, [params])

    useEffect(() => {
        if(!appConfig.CLIENTEX_APIBASE) {
            console.log("Invalid appConfig", appConfig);
            return;
        }
        const headers = { 
            "Ocp-Apim-Subscription-Key": appConfig.CLIENTEX_APIKEYVALUE,
            'Content-Type': 'application/json'
         };

        
        fetch(`${appConfig.CLIENTEX_APIBASE}/registration/${regId}`, { headers: headers, method: "get" })
        .then(data => data.json());
    }, [regId, appConfig])

    return (
        <div className="wrapper">
      <section className="login-content">
         <div className="container">
            <div className="row align-items-center justify-content-center height-self-center">
               <div className="col-lg-8">
                  <div className="card auth-card bg-light">
                     <div className="card-body p-0">
                        <div className="row align-items-center auth-content">
                           <div className="col-lg-7 align-self-center">
                              <div className="p-3">
                                 <h2 className="mb-2 text-center text-primary">Registration</h2>
                                 <p className="text-center text-grey">Confirm your details to create your account.</p>
                                 <div className="row justify-content-around align-items-center mt-3">
                                    <form>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Email</label>
                                                <input type="text" className="form-control" id="exampleInputDisabled5" disabled={true} value="email@mail.com" />
                                            </div>
                                        </div>
                                        <div className="form-row mt-2">
                                        <div className="col">
                                                <label>Full Name</label>
                                                <input type="text" className="form-control" id="exampleInputDisabled5" value="Diddy Dodat" />
                                            </div>
                                            <div className="col">
                                                <label>Birthday</label>
                                                <input type="date" className="form-control" id="exampleInputDisabled5"  />
                                            </div>
                                        </div>
                                    </form>
                                 </div>
                              </div>
                                <div className="row justify-content-center mt-4">
                                    <div className="mb-4">
                                        <a href="#" className="btn btn-primary  mr-3" data-target=".meeting-modal" data-toggle="modal" onClick={(e) => navigate('/main/user/viewinvitation?id=1234')}>Accept</a>
                                        <a href="chat-page.html" className="btn btn-light">Decline</a>
                                    </div>
                                </div>
                           </div>
                           <div className="col-lg-5 content-right">
                              <img src={EccoviaLogo} className="img-fluid image-right" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      </div>
    )
}

export default ViewRegistration;