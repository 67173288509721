import { AppConfigurationClient } from '@azure/app-configuration';
import { createContext, useEffect, useState } from "react";
import { IDictionary } from './Shared/Models';
import { wait } from './Utils/Timers';

//https://learn.microsoft.com/en-us/javascript/api/overview/azure/app-configuration-readme?view=azure-node-latest#authenticating-with-a-connection-string
const client = new AppConfigurationClient(process.env.REACT_APP_CONFIG_CONNECTION_STRING || "");

export class AppConfig {
    constructor(config: IDictionary<string>, isLoading: boolean = true) {
        this.IsLoading = isLoading;
        if(isLoading) {
            return;
        }

        this.B2C_AUTHORITY = config.B2C_AUTHORITY || getEnvironmentConfig("B2C_AUTHORITY");
        this.B2C_CHAT_READ_SCOPE = config.B2C_CHAT_READ_SCOPE || getEnvironmentConfig("B2C_CHAT_READ_SCOPE");
        this.B2C_CHAT_WRITE_SCOPE = config.B2C_CHAT_WRITE_SCOPE || getEnvironmentConfig("B2C_CHAT_WRITE_SCOPE");
        this.B2C_CLIENTID = config.B2C_CLIENTID || getEnvironmentConfig("B2C_CLIENTID");
        this.B2C_ENTITY_READ_SCOPE = config.B2C_ENTITY_READ_SCOPE || getEnvironmentConfig("B2C_ENTITY_READ_SCOPE");
        this.B2C_ENTITY_WRITE_SCOPE = config.B2C_ENTITY_WRITE_SCOPE || getEnvironmentConfig("B2C_ENTITY_WRITE_SCOPE");
        this.B2C_REGISTRATION_AUTHORITY = config.B2C_REGISTRATION_AUTHORITY || getEnvironmentConfig("B2C_REGISTRATION_AUTHORITY");
        this.B2C_SCOPE = config.B2C_SCOPE || getEnvironmentConfig("B2C_SCOPE");
        this.B2C_SIGN_IN_AUTHORITY = config.B2C_SIGN_IN_AUTHORITY || getEnvironmentConfig("B2C_SIGN_IN_AUTHORITY");
        this.B2C_URI = config.B2C_URI || getEnvironmentConfig("B2C_URI");
        this.B2C_USER_PROFILE_SCOPE = config.B2C_USER_PROFILE_SCOPE || getEnvironmentConfig("B2C_USER_PROFILE_SCOPE");
        this.CLIENTEXCHANGE_ENTITYID = config.CLIENTEXCHANGE_ENTITYID || getEnvironmentConfig("CLIENTEXCHANGE_ENTITYID");
        this.CLIENTEX_APIBASE = config.CLIENTEX_APIBASE || getEnvironmentConfig("CLIENTEX_APIBASE");
        this.CLIENTEX_APIKEYNAME = config.CLIENTEX_APIKEYNAME || getEnvironmentConfig("CLIENTEX_APIKEYNAME");
        this.CLIENTEX_APIKEYVALUE = config.CLIENTEX_APIKEYVALUE || getEnvironmentConfig("CLIENTEX_APIKEYVALUE");
        this.CONFIG_CONNECTION_STRING = config.CONFIG_CONNECTION_STRING || getEnvironmentConfig("CONFIG_CONNECTION_STRING");
        this.DATA_DOG_APP_ID = config.DATA_DOG_APP_ID || getEnvironmentConfig("DATA_DOG_APP_ID");
        this.DATA_DOG_CLIENT_TOKEN = config.DATA_DOG_CLIENT_TOKEN || getEnvironmentConfig("DATA_DOG_CLIENT_TOKEN");
        this.DATA_DOG_SERVICE = config.DATA_DOG_SERVICE || getEnvironmentConfig("DATA_DOG_SERVICE");
        this.DOMAIN = config.DOMAIN || getEnvironmentConfig("DOMAIN");
        this.ENV = config.ENV || getEnvironmentConfig("ENV");
        this.FEEDBACK_API = config.FEEDBACK_API || getEnvironmentConfig("FEEDBACK_API");
        this.PUBLIC_URL = config.PUBLIC_URL || getEnvironmentConfig("PUBLIC_URL");
        this.REGISTRATION_CALLBACK_URL = config.REGISTRATION_CALLBACK_URL || getEnvironmentConfig("REGISTRATION_CALLBACK_URL");
        this.SIGNALR_HUB = config.SIGNALR_HUB || getEnvironmentConfig("SIGNALR_HUB");
        this.SIGNALR_KEY = config.SIGNALR_KEY || getEnvironmentConfig("SIGNALR_KEY");
        this.TEAMMEMBER_REGISTRATION_STARTPAGE = config.TEAMMEMBER_REGISTRATION_STARTPAGE || getEnvironmentConfig("TEAMMEMBER_REGISTRATION_STARTPAGE");
    }
    IsLoading: boolean;
    B2C_AUTHORITY: string = '';
    B2C_CHAT_READ_SCOPE: string = '';
    B2C_CHAT_WRITE_SCOPE: string = '';
    B2C_CLIENTID: string = '';
    B2C_ENTITY_READ_SCOPE: string = '';
    B2C_ENTITY_WRITE_SCOPE: string = '';
    B2C_REGISTRATION_AUTHORITY: string = '';
    B2C_SCOPE: string = '';
    B2C_SIGN_IN_AUTHORITY: string = '';
    B2C_URI: string = '';
    B2C_USER_PROFILE_SCOPE: string = '';
    CLIENTEXCHANGE_ENTITYID: string = '';
    CLIENTEX_APIBASE: string = '';
    CLIENTEX_APIKEYNAME: string = '';
    CLIENTEX_APIKEYVALUE: string = '';
    CONFIG_CONNECTION_STRING: string = '';
    DATA_DOG_APP_ID: string = '';
    DATA_DOG_CLIENT_TOKEN: string = '';
    DATA_DOG_SERVICE: string = '';
    DOMAIN: string = '';
    ENV: string = '';
    FEEDBACK_API: string = '';
    PUBLIC_URL: string = '';
    REGISTRATION_CALLBACK_URL: string = '';
    SIGNALR_HUB: string = '';
    SIGNALR_KEY: string = '';
    TEAMMEMBER_REGISTRATION_STARTPAGE: string = '';
}
const configuration: IDictionary<string> = {}
let configLoaded = false;

export const AppConfigContext = createContext<AppConfig>(new AppConfig(configuration, !configLoaded));

function getEnvironmentConfig(key: string) : string {
    return process.env[key] || process.env["REACT_APP_" + key] || ""
}

export function clearCache() {
    configLoaded = false;
    const keys = Object.keys(configuration);
    for(const key of keys) {
        delete configuration[key];
    }
}

/** Get all configuration keys */
export async function loadConfig() : Promise<AppConfig> {
    if(!configLoaded) {
        for(let i = 0; i < 3; i++) {
            try {
                const settingsList = client.listConfigurationSettings();
                for await (const setting of settingsList) {
                    configuration[setting.key] = setting.value || "";
                }
                break;
            } catch(e) {
                console.error(`Failed to load configuration. Attempt ${i + 1} of 3.`, e);
                await wait(100);//wait 100ms before retrying
            }
        }
        configLoaded = true;
    }

    return new AppConfig(configuration, false);
}

export function useAppConfig() : AppConfig {
    const [config, setConfig] = useState<AppConfig>(new AppConfig(configuration, !configLoaded));
    const [isLoading, setIsLoading] = useState<boolean>(!configLoaded);
    useEffect(() => {
        if(isLoading) {
            loadConfig().then(config => {
                setConfig(config);
                setIsLoading(false);
            });
        }
        else {

        }
    }, [config, isLoading]);
    return config;
}