import { FunctionComponent, useContext, useEffect, useState } from "react";
import { ProviderRegistration, RegistrationCompletionStatus } from "../Shared/Models";

import ApiHelper from "../Shared/ApiHelper";
import EccoviaLogo from '../Shared/images/eccovia-logo-transparent.png';
import { useNavigate } from "react-router-dom";
import { ToastConfig } from "../Shared/ToastConfig";
import { UserProfileContext } from "../Hooks/useProfile";
import { AppConfigContext } from "../AppConfig";
import { B2cConfiguration } from "../Authentication/AuthConfig";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";

const ProviderRegistrationPage: FunctionComponent = () => {
    const [registration, setRegistration] = useState<ProviderRegistration | undefined>();
    const { instance } = useMsal();
    const [disableInput] = useState(false);
    const [registrationStatusText, setRegistrationStatusText] = useState<string[]>([]);
    const [api] = useState(new ApiHelper());
    const userProfile = useContext(UserProfileContext);
    const navigate = useNavigate();
    const appConfig = useContext(AppConfigContext);

    const handleOnSubmit = (e: any) => {
        e.preventDefault();
        if(!appConfig.CLIENTEX_APIBASE) return;

        const target = e.target;
        const formData = new FormData(target);
        const formObject = Object.fromEntries(formData.entries());
        const { b2cPolicies } = new B2cConfiguration(appConfig);
        api.callApi(
            instance,
            [appConfig.B2C_SCOPE],
            `${appConfig.CLIENTEX_APIBASE}/registration/provider`,
            "POST",
            JSON.stringify(formObject),
            undefined,
            b2cPolicies.authorities.signUpSignIn.authority
        ).then(async response => {
            var data: ProviderRegistration = await response.json(); 
            setRegistration(data);
            registrationTextSetter([data]);
            toast.success("Successfully submitted a provider account request.", ToastConfig)
        });
    }

    const registrationTextSetter = (registrations: ProviderRegistration[] | undefined) => {
        var statuses: string[] = [];
        registrations?.map( r => {
            switch (r.completionStatus) {
                case RegistrationCompletionStatus.pending:
                    statuses.push(`Your request for ${r.providerName} is under review.`);
                    break;
                case RegistrationCompletionStatus.declined:
                    statuses.push(`Your request for ${r.providerName} has been declined.`);
                    break;
            }
        })
        setRegistrationStatusText(statuses);
    }
    
    const handleBack = async () => {
        navigate(-1);
    }

    return (
        <div className="container">
            <div className="row align-items-center justify-content-center">
               <div className="col-lg-8">
                  <div onClick={handleBack} style={{cursor:'pointer'}}>
                    <i className="la la-arrow-circle-left mr-2"></i><span className="menu-text">Back to Provider Requests</span>
                  </div>
                  <div className="card auth-card bg-light">
                     <div className="card-body p-0">
                        <div className="row align-items-center auth-content">
                           <div className="col-lg-7 align-self-center">
                              <div className="p-3">
                                 <h3 className="mb-2 text-center text-primary">Provider Registration</h3>
                                 { registration !== undefined && 
                                    <>
                                        <div className="text-center">
                                            {registrationStatusText.map( (text: string) => (
                                                <p>
                                                    <i className="la la-exclamation-circle mr-2"></i><span>{text}</span>
                                                </p>
                                            ))}
                                        </div>
                                    </>
                                 }
                                { registration === undefined &&
                                 <>
                                    <p className="text-center text-grey">Provide your details to submit a provider account request.</p>
                                    <div className="justify-content-around align-items-center mt-3 w-100">
                                        <form onSubmit={handleOnSubmit} method="POST">
                                            <div className="form-row mb-2">
                                                <div className="col">
                                                    <label>Provider Name</label>
                                                    <input type="text" className="form-control" id="exampleInputDisabled1" defaultValue={userProfile?.AppUser?.providerRegistration?.providerName} name="providerName" />
                                                </div>
                                            </div>
                                            <div className="form-row mb-2">
                                                <div className="col">
                                                    <label>Email</label>
                                                    <input type="text" className="form-control" id="exampleInputDisabled2" readOnly defaultValue={userProfile?.B2CUser?.username} name="email" />
                                                </div>
                                            </div>
                                            <div className="form-row mb-2">
                                                <div className="col">
                                                    <label>Phone</label>
                                                    <input type="text" className="form-control" id="exampleInputDisabled3" disabled={disableInput} name="phone"/>
                                                </div>
                                            </div>
                                            <div className="form-row mb-2">
                                                <div className="col">
                                                    <label>Address</label>
                                                    <input type="text" className="form-control" id="exampleInputDisabled4" disabled={disableInput} placeholder={"Street 1"} name="street1" />
                                                    <input type="text" className="form-control" id="exampleInputDisabled4" disabled={disableInput} placeholder={"Street 2"} name="street2" />
                                                    <input type="text" className="form-control" id="exampleInputDisabled5" disabled={disableInput} placeholder={"City"} name="city" />
                                                    <input type="text" className="form-control" id="exampleInputDisabled6" disabled={disableInput} placeholder={"State"} name="state" />
                                                    <input type="text" className="form-control" id="exampleInputDisabled7" disabled={disableInput} placeholder={"Postal Code"} name="postalCode" />
                                                </div>
                                            </div>
                                            <div className="form-row mb-2">
                                                <div className="col">
                                                    <label>Tax ID (EIN/SSN)</label>
                                                    <input type="text" className="form-control" id="exampleInputDisabled8" name="taxid" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>NPI</label>
                                                    <input type="text" className="form-control" id="exampleInputDisabled9" name="npi" />
                                                </div>
                                            </div>

                                            <div className="justify-content-center">
                                                <div className="mt-2 mb-4 w-100">
                                                    <button className="btn btn-primary w-100" type="submit" disabled={registration !== undefined}>Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                 </> }
                              </div>
                           </div>
                           <div className="col-lg-5 content-right">
                              <img src={EccoviaLogo} className="img-fluid image-right" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
    )
}

export default ProviderRegistrationPage;